export const UPLOAD_PDF_START = 'UPLOAD_PDF_START';
export const UPLOAD_PDF_SUCCESS = 'UPLOAD_PDF_SUCCESS';
export const UPLOAD_PDF_FAIL = 'UPLOAD_PDF_FAIL';
export const UPLOAD_PDF = 'UPLOAD_PDF';

export const DOWNLOAD_PDF_START = 'DOWNLOAD_PDF_START';
export const DOWNLOAD_PDF_SUCCESS = 'DOWNLOAD_PDF_SUCCESS';
export const DOWNLOAD_PDF_FAIL = 'DOWNLOAD_PDF_FAIL';
export const DOWNLOAD_PDF = 'DOWNLOAD_PDF';

export const GET_SINGLE_PDF_DATA_START = 'GET_SINGLE_PDF_DATA_START';
export const GET_SINGLE_PDF_DATA_SUCCESS = 'GET_SINGLE_PDF_DATA_SUCCESS';
export const GET_SINGLE_PDF_DATA_FAIL = 'GET_SINGLE_PDF_DATA_FAIL';
export const GET_SINGLE_PDF_DATA = 'GET_SINGLE_PDF_DATA';
