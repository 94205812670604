export const START = 'START';
export const FAIL = 'FAIL';

export const ADD_COURSE_SUCCESS = 'ADD_COURSE_SUCCESS';
export const ADD_COURSE = 'ADD_COURSE';

export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';
export const GET_COURSES = 'GET_COURSES';

export const GET_ONE_COURSE = 'GET_ONE_COURSE';
export const GET_ONE_COURSE_SUCCESS = 'GET_ONE_COURSE_SUCCESS';

export const UPDATE_VIDEO = 'UPDATE_VIDEO';
export const UPDATE_VIDEO_SUCCESS = 'UPDATE_VIDEO_SUCESS';

export const UPDATE_COURSE = 'UPDATE_COURSE';
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS';

export const UPDATE_COURSE_LAST_WATCHED = 'UPDATE_COURSE_LAST_WATCHED';
export const UPDATE_COURSE_LAST_WATCHED_SUCCESS = 'UPDATE_COURSE_LAST_WATCHED_SUCCESS';

export const SET_CURRENT_COURSE = 'SET_CURRENT_COURSE';

export const ADD_SECTION_TO_COURSE = 'ADD_SECTIONS_TO_COURSE';
export const ADD_SECTION_TO_COURSE_SUCCESS = 'ADD_SECTIONS_TO_COURSE_SUCCESS';

export const UPLOAD_COURSE_TO_STORAGE_SUCCESS = 'UPLOAD_COURSE_TO_STORAGE_SUCCESS';
export const UPLOAD_COURSE_TO_STORAGE = 'UPLOAD_COURSE_TO_STORAGE';
export const UPDATE_VIDEO_BY_URL = 'UPDATE_VIDEO_BY_URL';
export const UPDATE_VIDEO_BY_URL_SUCCESS = 'UPDATE_VIDEO_BY_URL_SUCCESS';
