export const LOAD_RESOURCE_START = 'LOAD_RESOURCE_START';
export const LOAD_RESOURCE_FAIL = 'LOAD_RESOURCE_FAIL';
export const LOAD_RESOURCE_SUCCESS = 'LOAD_RESOURCE_SUCCESS';
export const LOAD_RESOURCE = 'LOAD_RESOURCE';

export const ADD_TOPIC_START = 'ADD_TOPIC_START';
export const ADD_TOPIC_SUCCESS = 'ADD_TOPIC_SUCCESS';
export const ADD_TOPIC_FAIL = 'ADD_TOPIC_FAIL';
export const ADD_TOPIC = 'ADD_TOPIC';

export const LOAD_ALL_TOPICS = 'LOAD_ALL_TOPICS';
export const LOAD_ALL_TOPICS_START = 'LOAD_ALL_TOPICS_START';
export const LOAD_ALL_TOPICS_SUCCESS = 'LOAD_ALL_TOPICS_SUCCESS';
export const LOAD_ALL_TOPICS_FAIL = 'LOAD_ALL_TOPICS_FAIL';

export const DELETE_TOPIC_START = 'DELETE_TOPIC_START';
export const DELETE_TOPIC_SUCCESS = 'DELETE_TOPIC_SUCCESS';
export const DELETE_TOPIC_FAIL = 'DELETE_TOPIC_FAIL';
export const DELETE_TOPIC = 'DELETE_TOPIC';

export const ADD_RESOURCE = 'ADD_RESOURCE';
export const ADD_RESOURCE_START = 'ADD_RESOURCE_START';
export const ADD_RESOURCE_SUCCESS = 'ADD_RESOURCE_SUCCESS';
export const ADD_RESOURCE_FAIL = 'ADD_RESOURCE_FAIL';

export const EDIT_TOPIC_START = 'EDIT_TOPIC_START';
export const EDIT_TOPIC_SUCCESS = 'EDIT_TOPIC_SUCCESS';
export const EDIT_TOPIC_FAIL = 'EDIT_TOPIC_FAIL';
export const EDIT_TOPIC = 'EDIT_TOPIC';

export const UPDATE_RESOURCE_START = 'UPDATE_RESOURCE_START';
export const UPDATE_RESOURCE_SUCCESS = 'UPDATE_RESOURCE_SUCCESS';
export const UPDATE_RESOURCE_FAIL = 'UPDATE_RESOURCE_FAIL';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';

export const DELETE_RESOURCE_START = 'DELETE_RESOURCE_START';
export const DELETE_RESOURCE_SUCCESS = 'DELETE_RESOURCE_SUCCESS';
export const DELETE_RESOURCE_FAIL = 'DELETE_RESOURCE_FAIL';
export const DELETE_RESOURCE = 'DELETE_RESOURCE';
